body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.changelance div {
  width: 190px !important;
  height: 190px !important;
  border-radius: 0px !important;
}



/* body,html{font-family: 'Open Sans', sans-serif; font-size:16px line-height:30px; font-weight: 400} */


.bannerImages {background-size: cover;
background-position: top center;height: 650px;}
header {text-align: center;padding-top: 50px;}
header > nav > ul > li {display: inline-block;}
header nav > ul > li > a {padding: 0px 30px;color: #fff;text-transform: uppercase;}
.bannerContent {display: block;text-align: center;margin-top: 22%;}
.bannerContent h1 {text-transform: uppercase;color: #fff;letter-spacing: 20px;line-height: 60px;}
.bannerContent p {color: #fff;text-transform: uppercase;font-size: 18px;}
.aboutCont {text-align: center;margin: 70px 0px 30px}
.aboutCont h3 {margin-bottom: 20px;text-transform: uppercase;}
.buttonAra {max-width: 370px;margin: 40px auto;}
.RedBtn {float: left;margin-bottom: 30px; cursor: pointer;}
p.ShadBoxv {height: 90px;overflow: hidden;transition: 0.3s;text-align:left}
p.ShadBoxv.disabledView {height: auto;}


.RevfinBtn{float: right;margin-bottom: 30px;}
.RedBtn span {display: block;border: 1px solid#423e3e;border-radius: 6px;padding: 10px 45px;color: #423e3e;
text-decoration: none;}
.RedBtn a:hover{text-decoration: none;color: inherit;}
.RevfinBtn a {display: block;border: 1px solid#ffa95c;border-radius: 6px;padding: 10px 45px;color: #fff;
text-decoration: none; background:#ffa95c;}
.RevfinBtn a:hover{text-decoration: none;color: #fff;}

.list_blogs {margin: 0px;padding: 0px;}
.list_blogs > li { list-style: none;;}
.list_blogs > li > a{text-decoration: none; color: #999; transition: 0.3s;}
.list_blogs > li > a:hover{color: #333;}
.row.m50 {margin-top: 60px;}
.gridB {margin-top: 0px;}

.fabCon {margin-top: 20px;}	
.fabCon > ul > li {display: inline-block;}
.fabCon > ul{margin: 0px; padding: 0px;}
.fabCon > ul > li > a {display: flex;width: 40px;height: 40px;border-radius: 50%;background: #423e3e;
align-items: center;justify-content: center;font-size: 18px;color: #fff;margin: 0px 3px;
transition: .5s;text-decoration: none}
.fabCon > ul > li > a:hover{background: #fff;box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
color: #423e3e;border: 1px solid#423e3e;}
.PannelArt {background: #ebebeb;border-radius: 10px;text-align: center;padding: 50px 0px 1px;}
h3.articalHeading {margin-bottom: 30px;text-align: center;text-transform: uppercase;}

.blogArt {margin-bottom: 40px;padding-bottom: 20px;}
.blogArt a {display: inline-block;background: #fff;padding: 7px 20px;border-radius: 20px;color: #423e3e;
text-decoration: none; text-decoration: none;}
.blogArt a:hover{text-decoration: none;color: inherit;}

.blogArt.withBgColor p {height: 29px;overflow: hidden;}
.PannelArt.maxh p {height: 29px;overflow: hidden;}
.blogArt p {margin-top: 20px;}
figure.sepm.blogspm {height: 172px;}

.articals {margin: 40px 0px 70px;}
.blogArt.withBgColor p {padding: 0px 20px;}
.blogArt p {padding: 0px 20px;}
.blogArt.withBgColor { max-width: calc(100% - 50px);background: #3a2e32;overflow: hidden;border-radius: 8px;
margin: 0 auto 40px auto;}
.blogArt.withBgColor p{color: #fff}
footer {background: #1c012a;padding: 30px 0px;}
h3.HeadingTestFooter {color: #fff;margin-bottom: 30px;}
.formHalfGrid input {border: 1px solid#959595;background: transparent;margin-bottom: 9px;display: block;
  padding: 8px 10px;width: 100%; color: #fff;}
 
::-webkit-input-placeholder {color: #959595;}
::-moz-placeholder {color: #959595;}
:-ms-input-placeholder {color: #959595;}
:-moz-placeholder {color: #959595;}

.foleFooter {margin-left: 60px;}

.formHalfGrid {width: 100%;display: inline-block;}
.formFullGrid textarea {border: 1px solid#959595;background: transparent;margin-bottom: 9px;display: block;
padding: 8px 10px;width: 100%;height: 95px; color: #fff;}
.formFullfGrid button {color: #1c012a;  display: inline-block;  padding: 9px 30px;  border-radius: 26px;  border: none;  background: #fff;}


.formGrid {margin-bottom: 10px;}
.formGrid label {display: block;color: #555;font-size: 14px; text-align: left;}
.formGrid input{display: block; width: 100%; padding:5px 15px}
.pop span {float: right;position: relative;top: -27px;right: -10px;text-align: justify;}
.buttonara button {background: #00b6ee;color: #fff;width: 100%;}
.buttonara {margin-top: 19px;}
a.newst {
  float: left !important;
}
figure.sepm {height: 166px; overflow: hidden;}
figure.sepm img {width: 100%;padding: 30px;}


.listNav > ul > li {display: inline-block;width: calc(50% - 3px);padding: 9px 10px;}
.listNav > ul {margin: 0px;padding: 0px;}
.listNav > ul > li > a{color:#fff; text-decoration: none;}
a.nav-link:hover{color: #fff;}

div.innrBanner {background: #a48d9e;padding: 0px 0px 30px;}
div.innterpage {padding: 80px 0px 60px;}
.mainHeading {text-align: center;text-transform: uppercase; font-weight: 600; margin-top: 50px; font-size: 30px; color:#fff}
.fitrbox img {width: 100%;}
.fitrbox {margin-bottom: 20px;height: 210px;overflow: hidden;}
.articalsBosx h3 {color: #423e3e;font-weight: bold; font-size: 18px;}
.diaAra p {float: left;position: relative;top: 11px;}
.diaAra a {float: right;background: #a48d9e;color: #fff !important;padding: 7px 20px;
  border-radius: 5px;border:none}
.articalsBosx {margin-bottom: 60px;border: 1px solid#ddd;box-shadow: 0px 0px 20px rgba(0,0,0,0.1);}
.articalsBosx h3 {height: 69px;}
.shaperdd {padding: 10px 15px 22px;}
.socialPan {text-align: center;margin: 90px 0px 30px;position: relative;}
.mediaPara img{width: 100%}
.mediaPara{margin: 0px 0px 120px;}
.liks span {background: #0288d1;width: 30px;height: 30px;color: #fff;display: inline-block;
text-align: center;border-radius: 50px;padding-top: 3px;font-size: 18px;}
.liks {margin-top: 20px;background: #ddd;padding: 10px 20px;border-radius: 6px 0px 6px 6px;position: absolute;
right: 0;top: -20px;}
.listBlog{margin:0px; padding: 0px}
.listBlog li{list-style: none; margin-bottom: 20px}
.firgsBlog {margin-bottom: 20px;}
.firgsBlog img{width: 100%}
.blosLeft{margin-bottom: 30px}
.blogContact button {margin-top: 30px;background: #f26522;border: none;color: #fff;padding: 10px 32px;
border-radius: 6px;}
.pannelLeftBlock {padding-left: 30px;}
.Changeform{text-align:center; margin-top: 10px;}
.FormAra textarea {width: 100%;background: #f3d7eb;border: 2px solid#cec5cb;height: 160px;}
.blogletter{font-weight: bold;}
button.Prev {background: #707070;color: #fff;border: none;padding: 10px 30px;}
button.post {background: #464646;color: #fff;border: none;padding: 10px 30px;}
div.post {background: #464646;color: #fff;border: none;padding: 10px 30px; cursor: pointer; max-width: 200px; margin:0 auto}
.formBtn {position: relative;margin-top: 0px;}
.articalsBosx:hover a {text-decoration: none;color: inherit;}
.articalsBosx a {text-decoration: none;color: inherit;}
.barna{display: none; cursor: pointer;}
.ShadBox {position: fixed;top: 0;left: 0;right: 0;bottom: 0;background: rgba(0,0,0,0.5); visibility: hidden; opacity: 0; z-index: 100;}

.pop {max-width: 780px;height: auto;z-index: 10000;position: fixed;background: #fff;top: 50%;left: 50%;
transform: translate(-50%,-50%);padding: 30px 20px 20px 20px;border-radius: 3px; visibility: hidden; opacity: 0;width:96%;}
.colseBtn {position: relative;float: right;top: -26px;cursor: pointer;width: 25px;height: 25px;
display: flex;align-items: center;justify-content: center;font-size: 20px;font-weight: 300;}
.ShadBox.disabledView {opacity: 1;visibility: visible;}
.pop.disabledView {visibility: visible;opacity: 1;}
.formgrid{margin-bottom: 15px;}
.formgrid label {display: block; text-align: left;}
.inputwald {display: block;width: 100%;padding: 6px 10px;border-radius: 5px;border: 1px solid#ddd;box-shadow: 0px 0px 7px rgba(0,0,0,0.1);}

.formgridchck input {width: 20px;}
span.formcontent {margin-left: 12px;font-size: 12px;margin-top: 5px;}
button.sumbitBtn {background: #1c012a;width: 100%;padding: 10px;color: #fff;border: none;border-radius: 3px;}
.btnShad.disabledView { visibility: visible; opacity: 1;} 
.btnShad{visibility: hidden; opacity: 0; display: block;background: rgba(255,255,255,0.7);position: relative;height: 46px;width: 100%;
  margin-top: -18px;left: 0;}

.formgridchck {padding: 0px 20px 0px 0px;}

.blogsPannel h1 {font-weight: bold;}
.iconsLike {position: absolute;top: -70px;right: 0;}
.iconsLike button {border-radius: 6px 0px 6px 6px;border: none;padding: 10px 20px;background: #dddddd85; position: relative;}
span.likecount {padding: 0px 8px;}
span.thumbIcon {width: 30px;height: 30px;margin-left: 12px;background: #0288d1;color: #fff;display: inline-block;border-radius: 30px;padding-top: 3px;}
.shaperdd.withnob h3 {height: 25px;}


.formHalfGrid input::-webkit-input-placeholder {color:#fff;}
.formHalfGrid input:-ms-input-placeholder { color:#fff;}
::placeholder {color:#fff;}

p.ShadBoxv.disabledView div {margin-bottom: 12px;}
.ShadBoxv div{margin-bottom: 12px;}
.thaxwala {position: fixed;top: 50%;left: 50%;background: #fff;z-index: 100000010;max-width: 441px; width:96%;transform: translate(-50%, -50%);padding: 30px;
  text-align: center; opacity:0;visibility: hidden;box-shadow: 0px 0px 10px rgba(0,0,0,0.1);}
.whitbg {background: #000000d4;position: fixed;z-index: 100000000;left: 0;right: 0;
bottom: 0;top: 0; visibility: hidden; opacity: 0;}
.thaxwala h3 {margin-bottom: 30px;}

.thaxwala button {background: #1c012a;width: 100%;padding: 10px;color: #fff;border: none;border-radius: 3px;}
.thaxwala.disabledView {visibility: visible;opacity: 1;}
.whitbg.disabledView {visibility: visible;opacity: 1;}
span.tiemdates {font-size: 11px;color:#6f6f6f;position: relative;top: -18px;}
.FormAra label {display: block;float: left;width: 100%;text-align: left;}
.FormAra input{width: 100%;background: #f3d7eb;border: 2px solid#cec5cb; border:none ; padding: 6px 15px;}
.FormAra{margin-bottom: 15px;}

/* .col-md-4.col-xs-12.col-sm-4.parMargen {padding-right: 5px;padding-left: 5px;}
.col-md-12.parMargen {padding-right: 5px;padding-left: 5px;} */
.froaratwo .row {margin: 0;padding: 0px;}
.froaratwo {background: #ffffff;border-radius: 3px;padding: 10px 0px 20px;box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  border: 1px solid#ddd;}
h3.newformfont {margin-bottom: 3px;text-align: center;margin-top: 60px;}
.row.m50.auto {height: 300px;overflow: auto;}
.formBtn.pads20{margin-top: 20px;}
.npopshadow {position: fixed;top: 0;left: 0;right: 0;bottom: 0;background: rgba(0,0,0,0.8);z-index: 1000;  opacity: 0; visibility: hidden;}
.npop {position: fixed;top: 50%;left: 50%;background: #fff;z-index: 1000;transform: translate(-50%, -50%);    padding: 20px 20px 36px;
max-width: 400px;width: 100%; opacity: 0; visibility: hidden;}

.npopshadow.disabledView {visibility: visible;opacity: 1;}
.npop.disabledView{visibility: visible;opacity: 1;}
.closehan {float: right;position: relative;top: -10px;right: -10px;width: 25px;height: 26px;cursor: pointer;}

.relatbox {position: relative;}
span.nott {position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 1000;}
span.nott.disabledView {display: none;}
.commentclos {float: right;position: relative;top: -14px;right: -8px;cursor: pointer;}
p.ShadBoxv.disabledView span {display: block;margin-bottom: 16px;}
p.ShadBoxv span{display: block;margin-bottom: 16px;}







@media screen and (max-width:1350px) {}
@media screen and (max-width:992px) {
.bannerImages {background-size: cover;background-position: top center;height: auto;
padding-bottom: 50px;}
.blogArt.withBgColor {max-width: 90%;}
.blogArt {width: 90%;margin: 0 auto;}
.blogArt img {width: 100%;}
.FormAra textarea{width:100%;}
}
@media screen and (max-width:768px) {
.formFullfGrid button {width: 100%;margin-bottom: 30px;}
.articalsBosx h3 {font-size: 18px; }
.articalsBosx a {text-decoration: none;color: inherit;}
.foleFooter {margin-left: 30px;}
.pannelLeftBlock {padding-left:0px;}
}
@media screen and (max-width:630px) {
  
.bannerContent {margin-top: 5%;}
.blosLeft h3 {font-size: 18px;}
nav {position: fixed;left: -100%;top: 0px;bottom: 0px;width: 280px;
  background: #292929; transition:0.4s; z-index: 1110;}
.blogContact h3 {font-size: 18px;}
nav > ul{margin:0px; padding: 0px;}
header > nav > ul > li {display:block;}
.nav-link {text-align: left;color: #989898;}
.nav-link {display: block;padding: .5rem 1rem;border-bottom: 1px solid#989898;}
nav.animated {left: 0px;}
.bannerContent h1 {letter-spacing: 3px;line-height: 37px;font-size: 30px;}
.shade {display: block;position: fixed;top: 0;right: 0;bottom: 0;
left: 0;background: rgba(0,0,0,0.6);transition: .4s;z-index: 1000;}
.topBar {position: absolute;top: 0px;height: 2px;width: 100%;background: #fff;}
.midBar {position: absolute;top: 6px;height: 2px;width: 100%;background: #fff;}
.botBar {position: absolute;top: 12px;height: 2px;width: 100%;background: #fff;}
.blogsPannel h1 {font-size: 22px;}
.blogsPannel h3 {font-size: 18px;}
.bannerContent p {color: #fff;text-transform: uppercase;font-size: 12px;}
.aboutCont h3,h3.articalHeading,h3.HeadingTestFooter,.mainHeading,.socialPan h3 {font-size: 23px;margin-top: 0px;}
div.innrBanner {background: #a48d9e;padding: 0px 0px 30px;}



}
@media screen and (max-width:575px) {
  .liks{position: static; margin-bottom: 20px;}
.RedBtn a {padding: 10px 20px;width:100%}
.RedBtn{float: none;width:100%;}
.RevfinBtn{float: none;width:100%;}
.PannelArt {margin-bottom: 50px;}
.mediaPara .col-md-4.col-xs-12.col-sm-4 {margin-bottom: 20px;}
.formFullfGrid button {width: 100%;margin-bottom: 30px;}
.foleFooter {margin-left: 0px;}
.blogContact button{width:100%}
.barna {display: block;float: right;color: #fff;margin-right: 15px;cursor: pointer;
width: 25px;height: 23px;position: relative;top: -30px;}
}
@media screen and (max-width:480px) {}